  .navbar {
    /*background-color: #194e25 !important;
    border-bottom: 1px solid #267754;*/
    background-color: #003720 !important;

  }

  .navbar a {
    color: #fff !important;
    font: 400 22px "Dosis", sans-serif;
    margin-bottom: 15px !important;

  }

  .navbar ul {
    padding-top: 15px;
  }

  .navbar .logo-1 {
    height: auto;
    max-width: 100%;
    width: 120px;
  }

  .navbar .icon {
    padding-right: 5px;
    color: #70c745 !important;
  }
